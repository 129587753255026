/* eslint-disable no-param-reassign */
export default {
  state: {
    productsInCart: [],
  },
  getters: {
    productsInCart(state) {
      return state.productsInCart;
    },
  },
  actions: {
    addProductToCart(context, product) {
      context.commit('addProductToCart', product);
    },
    updateProductToCart(context, updatedData, productId) {
      context.commit('updateProductInCart', updatedData, productId);
    },
    removeProductOfCart(context, productId) {
      context.commit('removeProductOfCart', productId);
    },
    clearProductsInCart(context) {
      context.commit('clearProductsInCart');
    },
  },
  mutations: {
    addProductToCart(state, product) {
      state.productsInCart.push(product);
    },
    updateProductInCart(state, updatedData) {
      const indexOfProductToUpdate = state.productsInCart.findIndex(
        (p) => p.id === updatedData.id,
      );
      state.productsInCart[indexOfProductToUpdate] = {
        ...state.productsInCart[indexOfProductToUpdate],
        ...updatedData,
      };
    },
    removeProductOfCart(state, productId) {
      const productIndexToRemove = state.productsInCart.findIndex((p) => p.id === productId);
      state.productsInCart.splice(productIndexToRemove, 1);
    },
    clearProductsInCart(state) {
      state.productsInCart = [];
    },
  },
};
