<template>
  <v-bottom-sheet :value="!hasUserAcceptedCookies">
    <v-sheet
      class="text-center py-5"
    >
      <div class="px-10">
        Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site.<br>
        Si vous continuez à utiliser ce dernier,
        nous considérerons que vous acceptez l'utilisation des cookies.
      </div>
      <v-btn
        class="mt-7 px-8"
        color="primary"
        @click="onCookiesAccept()"
      >
        J'ai compris
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['hasUserAcceptedCookies']),
  },
  methods: {
    ...mapActions(['setCookiesAsAccepted']),
    onCookiesAccept() {
      this.setCookiesAsAccepted();
    },
  },
};
</script>

<style>

</style>
