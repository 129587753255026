import jwtDecode from 'jwt-decode';

/* eslint-disable no-param-reassign */
export default {
  state: {
    token: null,
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    token(state) {
      return state.token;
    },
    tokenPayload(state) {
      if (state.token) {
        return jwtDecode(state.token);
      }
      return {};
    },
  },
  actions: {
    setToken(context, token) {
      context.commit('setToken', token);
    },
    clearToken(context) {
      context.commit('clearToken');
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = null;
    },
  },
};
