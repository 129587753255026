<template>
  <v-snackbar
    :value="showSnackbar"
    :color="snackbarColor"
    bottom
    :left="!$vuetify.breakpoint.mobile"
    @input="getSnackbarValue($event)"
  >
    {{ snackbarMessage }}
  </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['showSnackbar', 'snackbarMessage', 'snackbarColor']),
  },
  methods: {
    ...mapMutations(['setShowSnackbar']),
    getSnackbarValue(value) {
      this.setShowSnackbar(value);
    },
  },
};
</script>

<style>

</style>
