/* eslint-disable no-param-reassign */
export default {
  state: {
    hasUserAcceptedCookies: false,
  },
  getters: {
    hasUserAcceptedCookies(state) {
      return state.hasUserAcceptedCookies;
    },
  },
  actions: {
    setCookiesAsAccepted(context) {
      context.commit('setCookiesAsAccepted');
    },
  },
  mutations: {
    setCookiesAsAccepted(state) {
      state.hasUserAcceptedCookies = true;
    },
  },
};
