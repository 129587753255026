<template>
  <v-app>
    <header-layout />
    <v-main class="brown lighten-5 layout-main">
      <v-container class="pt-0 pb-8">
        <!-- Provides the application the proper gutter -->
        <router-view />
      </v-container>
    </v-main>
    <footer-layout />
    <snackbar />
    <cookies-consent-layout />
  </v-app>
</template>

<script>
import HeaderLayout from './layouts/HeaderLayout.vue';
import FooterLayout from './layouts/FooterLayout.vue';
import CookiesConsentLayout from './layouts/CookiesConsentLayout.vue';
import Snackbar from './components/Snackbar.vue';

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    CookiesConsentLayout,
    Snackbar,
  },
};
</script>

<style scoped>
.layout-main {
  min-height: 100vh;
}
</style>
