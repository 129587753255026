import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Modules
import authModule from './auth';
import snackbarModule from './snackbar';
import cartModule from './cart';
import cookiesModule from './cookies';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authModule,
    snackbarModule,
    cartModule,
    cookiesModule,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()],
});
