<template>
  <v-footer padless>
    <v-card
      flat
      tile
      color="primary"
      width="100%"
    >
      <v-card-text>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            v-for="socialNetwork in socialNetworks"
            :key="socialNetwork.url"
            class="mx-2 white--text"
            icon
            @click="onSocialNetwork(socialNetwork)"
          >
            <v-icon size="24px">
              {{ socialNetwork.icon }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-text>

      <v-divider class="mb-2" />

      <v-card-text class="white--text text-center">
        <v-row justify="center">
          <span
            class="pointer"
            @click="isMentionsLegalesDialogVisible = true"
          >Mentions légales</span>
          <span
            class="ml-3 pointer"
            @click="isConditionsGeneralesDialogVisible = true"
          >Conditions générales de vente</span>
        </v-row>
      </v-card-text>

      <v-card-text class="white--text text-center pt-1">
        © 2023 La malle de lili
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="isMentionsLegalesDialogVisible"
      width="650"
    >
      <v-card>
        <v-card-title class="text-h5 primary white--text justify-center">
          Mentions légales
        </v-card-title>

        <v-card-text class="py-5">
          En vertu de l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance
          dans l’économie
          numérique, vous trouverez ci-dessous l'identité des responsables du présent site internet
          www.lamalledelili.com.

          <div class="my-2">
            <span class="font-weight-bold">Propriétaire :</span>
            <br>
            La malle de lili est une micro entreprise est représentée par Mme Lydia Hamelin
            <br>
            4 rue Pauline Kergomard
            <br>
            86100 Châtellerault
            <br>
            Email : contact@lamalledelili.com
            <br>
            La malle de lili est immatriculée au RCS sous le numéro SIRET 84392912600012
            <br>
            APE : 4791B
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Responsable publication :</span> Mme Lydia Hamelin
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Hébergeur :</span> OVH
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Délégué à la protection des données :</span>
            Mme Lydia Hamelin
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isConditionsGeneralesDialogVisible"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 primary white--text justify-center">
          Conditions générales de vente
        </v-card-title>

        <v-card-text
          class="py-5"
        >
          Le présent document établit les conditions générales de vente conclues d'une part entre le
          propriétaire de La malle de lili et toute personne physique ou morale dénommée client ou
          acheteur souhaitant procéder à l'achat d'un produit via le site internet
          www.lamalledelili.com pour ses besoins personnels, en ayant préalablement
          adhéré aux Conditions Générales de Vente.

          <div class="my-2">
            <span class="font-weight-bold">Article 1 : Acceptation des conditions de vente</span>
            <br>
            Pour effectuer un achat, le client doit impérativement cocher la case « j'ai lu et
            j'accepte les conditions de vente ». Cette acceptation a valeur d'adhésion sans réserve
            aux conditions de vente exposées dans ce document.
            <br>
            En acceptant les conditions générales de vente, le client garantit qu'il est majeur et
            dans la capacité légale de passer un contrat, que les coordonnées données lors de la
            passation d'une commande sont complètes et exactes, qu'il n'utilise pas un faux nom
            ou celui d'un tiers sans autorisation de ce dernier.
            <br>
            La malle de lili conserve la possibilité de modifier à tout moment ces conditions de
            vente afin de respecter toute nouvelle réglementation ou dans le but d'améliorer
            l'utilisation du site. De ce fait, les conditions applicables seront celles en vigueur
            à la date de la commande par le client.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 2 : Produit</span>
            <br>
            Les produits proposés sont ceux qui figurent sur le site www.lamalledelili.com de la
            société La malle de lili dans la limite des stocks disponibles.
            Tous les produits présentés sont faits main en France par Mme Lydia Hamelin dirigeante
            de la société La malle de lili. La société La malle de lili se réserve le droit de
            modifier à tout moment l'assortiment des produits. Chaque produit est présenté sur
            le site internet avec un descriptif non exhaustif reprenant ses caractéristiques
            techniques. La malle de lili ne sera tenue responsable de manquement ou d'erreur
            d'information du fait de ses fournisseurs. Les photographies sont le plus fidèles
            possibles mais n' engagent en rien le vendeur.
            <br>
            En cas de doute, le client peut contacter Lydia Hamelin via la messagerie
            contact@lamalledelili.com
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 3 : Tarifs</span>
            <br>
            Les prix figurant sur les fiches produits du catalogue internet sont en euros (€) toutes
            taxes comprises. La société La malle de lili se réserve le droit de modifier ses prix à
            tout moment, étant entendu que le prix figurant au catalogue au moment de la commande
            sera le prix applicable à l'acheteur.
            <br>
            Les prix indiqués ne comprennent pas les frais de livraison facturés en supplément du
            prix des produits achetés.
            <br>
            La malle de lili étant une micro entreprise, la TVA n'est pas applicable
            (article 293 B du CGI).
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 4 : Commande</span>
            <br>
            L'acheteur sélectionne le produit qu'il souhaite acheter, modifie si besoin la couleur
            et la taille puis l'ajoute au panier. Il renseigne le pays de livraison, les frais de
            livraisons seront alors calculés et soumis à l'acheteur. Enfin, l'acheteur doit
            accepter les conditions générales de vente pour valider sa commande.
            Il sera alors orienté vers le paiement.
            <br>
            La malle de lili se réserve le droit de ne pas valider une commande pour
            diverses raisons : problème lors de la passation de la commande, problème de paiement,
            problème d'approvisionnement de fournitures de base essentielles à la
            création du produit, adresse de livraison erronée.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 5 : Paiement</span>
            <br>
            Le règlement s'effectue au moment de la commande, par carte bancaire à partir du
            module de paiement Stripe (saisie sécurisée par cryptage SSL).
            <br>
            A titre exceptionnel, d'autres moyens de paiement peuvent être acceptés :
            virement bancaire, Paypal.
            Dans ce cas, le client doit prendre contact avec Lydia Hamelin, dirigeante de la société
            La malle de lili via la messagerie : contact@lamalledelili.com
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 6 : Livraison</span>
            <br>
            Les livraisons sont faites à l'adresse indiquée sur le site au moment de la commande.
            <br>
            Après l'expédition de la commande, l'acheteur recevra un mail à l'adresse indiquée
            lors de la passation de la commande, indiquant le numéro de suivi du colis.
            <br>
            Toutes les livraisons sont effectuées par la Poste. En fonction de la taille et du poids
            du colis, l'envoi se fera par lettre avec suivi ou par Colissimo sans signature. Dans
            tous les cas, un numéro de suivi vous sera adressé dés l'envoi de votre commande.
            En cas de dommage pendant le transport, toute protestation doit être effectuée auprès
            de la Poste. La société La malle de lili ne peut être tenue responsable de dégradation
            du produit acheté du fait du transporteur.
            <br>
            Les délais de livraison sont donnés à titre indicatif. La société La malle de lili ne
            pourra être tenu responsable d'un retard de livraison ou de la perte d'un colis
            par la Poste.
            <br>
            Les frais de livraison sont fixes et forfaitaires quel que soit le nombre d'articles
            achetés. Le montant de ces frais de livraison est lié au pays de destination.
            Le tarif appliqué et de 4,50€ euros pour la France métropolitaine et de 6,50 €
            pour les autres destination.
            <br>
            Dans certains pays des droits de douane, des taxes locales peuvent être réclamées à
            l'acheteur. Les taxes ne sont pas du ressort de La malle de lili.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 7 : Droit de rétractation et remboursement</span>
            <br>
            L'acheteur dispose d'un droit de rétractation
            (article L121-20 du Code de la consommation), dans un délai de quatorze jours ouvrables
            à compter de la réception du ou des articles. Le client devra retourner le ou les
            produits qu'il ne souhaite pas conserver à l'adresse :
            La malle de lili 4 rue Pauline Kergomard 86100 Châtellerault. Les frais de retour sont à
            la charge de l'acheteur. L'acheteur devra également dans ce délai de 4 jours ouvrable à
            compter de la réception du ou des articles, informer La malle de lili de son souhait de
            retourner un article et d'être remboursé. Dans un délai de 7 jours à compter de la
            réception de l'article, l'acheteur sera remboursé par La malle de lili à condition que
            le produit retourné ne soit pas sali, pas endommagé, qu'il n'a pas été porté et qu'il
            soit renvoyé dans son emballage d'origine.
            <br>
            L'acheteur sera remboursé du montant du ou des articles retournés, les frais de
            livraison ne font pas l'objet d'un remboursement. La malle de lili procédera au
            remboursement en utilisant le même moyen de paiement que celui utilisé pour la
            transaction initiale, sauf si le consommateur exprime le souhait
            d'utiliser un autre moyen.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 8 : Traitement des données à caractère
              personnel
            </span>
            <br>
            L'acheteur dispose d'un droit de rétractation
            (article L121-20 du Code de la consommation), dans un délai de quatorze jours ouvrables
            à compter de la réception du ou des articles. Le client devra retourner le ou les
            produits qu'il ne souhaite pas conserver à l'adresse :
            La malle de lili 4 rue Pauline Kergomard 86100 Châtellerault. Les frais de retour sont à
            la charge de l'acheteur. L'acheteur devra également dans ce délai de 4 jours ouvrable à
            compter de la réception du ou des articles, informer La malle de lili de son souhait de
            retourner un article et d'être remboursé. Dans un délai de 7 jours à compter de la
            réception de l'article, l'acheteur sera remboursé par La malle de lili à condition que
            le produit retourné ne soit pas sali, pas endommagé, qu'il n'a pas été porté et qu'il
            soit renvoyé dans son emballage d'origine.
            <br>
            L'acheteur sera remboursé du montant du ou des articles retournés, les frais de
            livraison ne font pas l'objet d'un remboursement. La malle de lili procédera au
            remboursement en utilisant le même moyen de paiement que celui utilisé pour la
            transaction initiale, sauf si le consommateur exprime le souhait
            d'utiliser un autre moyen.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 9 : Juridiction compétente et
              droit applicable
            </span>
            <br>
            En cas de litige entre La malle de lili et le client, le droit applicable est le
            droit français. Seules les juridictions françaises sont compétentes
            pour répondre à ce litige.
          </div>

          <div class="my-2">
            <span class="font-weight-bold">Article 10 : Service client</span>
            <br>
            Pour toute question sur un article, une commande La malle de lili.com via la messagerie
            contact@lamalledelili.com
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    socialNetworks: [
      {
        url: 'https://www.instagram.com/lamalledelili/',
        icon: 'mdi-instagram',
      },
      {
        url: 'https://www.facebook.com/profile.php?id=100083101197921',
        icon: 'mdi-facebook',
      },
    ],
    isMentionsLegalesDialogVisible: false,
    isConditionsGeneralesDialogVisible: false,
  }),
  methods: {
    onSocialNetwork(socialNetwork) {
      window.open(socialNetwork.url, '_blank', 'noreferrer');
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
