/* eslint-disable no-param-reassign */
export default {
  state: {
    snackbarMessage: '',
    snackbarColor: '',
    showSnackbar: false,
  },
  getters: {
    snackbarMessage(state) {
      return state.snackbarMessage;
    },
    showSnackbar(state) {
      return state.showSnackbar;
    },
    snackbarColor(state) {
      return state.snackbarColor;
    },
  },
  actions: {
    showSnackbarMessage(context, snackbarOptions) {
      context.commit('setSnackbarMessage', snackbarOptions.message);
      context.commit('setSnackbarColor', snackbarOptions.color);
      context.commit('setShowSnackbar', true);
    },
  },
  mutations: {
    setSnackbarMessage(state, message) {
      state.snackbarMessage = message;
    },
    setSnackbarColor(state, color) {
      state.snackbarColor = color;
    },
    setShowSnackbar(state, isSnackbarOpen) {
      state.showSnackbar = isSnackbarOpen;
    },
  },
};
