import Vue from 'vue';
import axios from 'axios';
import vuelidate from 'vuelidate';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(vuelidate);

// Interceptors
axios.interceptors.request.use(
  (config) => {
    const configuration = config;
    if (store.getters.token) {
      configuration.headers.Authorization = `Bearer ${store.getters.token}`;
    }
    return configuration;
  },
  (error) => Promise.reject(error),
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
