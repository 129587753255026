import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import('../views/Shop.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/product/new',
      name: 'product-new',
      component: () => import('../views/ProductForm.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/product/:id/edit',
      name: 'product-edition',
      component: () => import('../views/ProductForm.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/product/:id',
      name: 'product-details',
      component: () => import('../views/Product.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('../views/ProductCart.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/checkout/success',
      name: 'cart',
      component: () => import('../views/CheckoutSuccess.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/admin/login',
      name: 'admin-login',
      component: () => import('../views/AdminLogin.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/admin/configuration',
      name: 'admin-configuration',
      component: () => import('../views/AdminConfiguration.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/Contact.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth
    && (!store.getters.isLoggedIn || Date.now() >= store.getters.tokenPayload.exp * 1000)) {
    store.dispatch('clearToken');
    router.push({ name: 'admin-login' });
  }

  next();
});

export default router;
