<template>
  <div>
    <v-app-bar
      app
      color="primary"
    >
      <v-row
        v-if="$vuetify.breakpoint.xsOnly"
        align="center"
      >
        <a
          class="brand-name ml-3"
          @click="goToHome()"
        >
          La malle de Lili
        </a>

        <v-spacer />
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.xsOnly"
          color="white"
          class="mr-3"
          @click="drawer = true"
        />
      </v-row>

      <v-container
        v-else
        class="py-0 fill-height"
      >
        <a
          class="brand-name"
          @click="goToHome()"
        >
          La malle de Lili
        </a>

        <v-spacer />

        <v-btn
          v-for="link in linksToShow"
          :key="link.routeLabel"
          text
          class="white--text"
          @click="goToLink(link.routeName)"
        >
          {{ link.routeLabel }}
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="link in linksToShow"
            :key="link.routeLabel"
            @click="goToLink(link.routeName)"
          >
            <v-list-item-icon>
              <v-icon>mdi-circle-small</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.routeLabel }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    drawer: false,
    group: null,
    links: [
      {
        routeName: 'admin-configuration',
        routeLabel: 'Configuration',
        onlyAdmin: true,
      },
      {
        routeName: 'shop',
        routeLabel: 'Boutique',
        onlyAdmin: false,
      },
      {
        routeName: 'contact',
        routeLabel: 'Contact',
        onlyAdmin: false,
      },
      {
        routeName: 'cart',
        routeLabel: 'Panier',
        onlyAdmin: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(['isLoggedIn']),
    linksToShow() {
      return this.links.filter((link) => !link.onlyAdmin || (link.onlyAdmin && this.isLoggedIn));
    },
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    goToLink(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.brand-name {
  font-family: 'Pacifico', cursive;
  font-size: 2.4em;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.header .v-toolbar__content {
  box-shadow: 0px 2px 3px 0px #00000096;
}
</style>
